<template>
  <div class="wrapper flex fd-c ai-c">
      <div class="title">点击选择你要的头像</div>
      <div class="list flex ai-c fw-w jc-sa">
          <div class="item" v-for="(item, index) in 14" 
          @click="active=index"
          :class="active==index&&'active'" :key="index">
              <img class="portrait" :src="'/img/portrait/'+index+'.png'" alt="">
          </div>
          <div class="item" v-for="(item, index) in 2" :key="index+'k'">
          </div>
      </div>
      <div class="btn flex ai-c jc-c" @click="Click">确认头像</div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
        active: 0
    }
  },
  watch:{},
  computed:{},
  methods:{
      Click() {
          this.$toast.success('修改成功')
          localStorage.setItem('portrait',this.active)
          this.$router.go(-1)
      }
  },
  created(){
      var portrait = localStorage.getItem('portrait')
      if (portrait!=null) {
          this.active = portrait
      } 
  },
  mounted(){}
}
</script>
<style scoped>
.wrapper{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 2rem;
    box-sizing: border-box;
    background-color: RGBA(238, 238, 238, 1);
}
.wrapper .title{
    font-size: .36rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin-top: .7rem;
}
.list{
    width: 7.1rem;
    margin-top: .8rem;
}
.item{
    width: 1.3rem;
    height: 1.3rem;
    border: .08rem solid RGBA(238, 238, 238, 1);
    border-radius: 50%;
    margin-bottom: .4rem;
}
.item .portrait{
    width: 100%;
    height: 100%;
}
.active{
    position: relative;
    border-color: rgba(249, 111, 48, 1);
}
.active::after{
    content: '';
    position: absolute;
    top: .24rem;
    left: 1.1rem;
    width: .34rem;
    height: .33rem;
    background-image: url(/img/icon-gou.png);
    background-size: 100% 100%;
}
.btn{
    position: fixed;
    bottom: .58rem;
    left: calc(50% - 3.55rem);
    width: 7.10rem;
    height: .90rem;
    background: #F96F30;
    border-radius: .45rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
}
</style>